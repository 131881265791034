<template>
  <div class="container">
    <div @click="goback" style="font-size:14px;margin-bottom:10px;cursor: pointer;width:50px">
      <i class="el-icon-back"></i>
      返回
    </div>
    <div class="search" style="display:flex">
      <el-form ref="form" style="flex:1" :inline="true" :model="search" class="demo-form-inline">
        <el-form-item>
          <el-input v-model="search.kname" @change="Search" placeholder="模糊搜索标题名称"></el-input>
        </el-form-item>
        <el-form-item>
          <el-select v-model="search.language_code" clearable @change="Search" placeholder="语言缩写">
            <el-option
              v-for="item in languagelist"
              :key="item.code"
              :label="item.name"
              :value="item.code">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div>
        <router-link :to="'/iot/contentmanage/rule/contentadd/'+content_code+'&'+ruleType+'&'+search.content_rule_code+'&'+showMsg.jump_url_type">
          <el-button type="primary" size="small">增加</el-button>
        </router-link>
      </div>
    </div>
    <!-- 列表 -->
    <el-table border v-bind:data="list">
      <!-- <el-table-column label="序号" type="index" width="50"></el-table-column> -->
      <el-table-column label="内容信息">
        <template v-slot="scope">
          <span>{{ scope.row.content }}</span>
        </template>
      </el-table-column>
      <el-table-column label="图片或富文本url">
        <template v-slot="scope">
          <span>{{ scope.row.img_url }}</span>
        </template>
      </el-table-column>
      <el-table-column label="单击图片的跳转地址">
        <template v-slot="scope">
          <span>{{ scope.row.jump_url }}</span>
        </template>
      </el-table-column>
      <el-table-column label="语言简码">
        <template v-slot="scope">
          <span>{{ scope.row.language_code }}</span>
        </template>
      </el-table-column>
      <el-table-column label="标题信息">
        <template v-slot="scope">
          <span>{{ scope.row.title }}</span>
        </template>
      </el-table-column>
      <el-table-column label="状态">
        <template v-slot="scope">
          <el-select v-model="scope.row.status" @change="ChangeStatus(scope.row)">
            <el-option label="下架" :value="1"></el-option>
            <el-option label="测试" :value="2"></el-option>
            <el-option label="上架" :value="3"></el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="250px">
        <template v-slot="scope">
          <router-link style="margin-right:10px" :to="'/iot/contentmanage/rule/contentadd/'+content_code+'&'+ruleType+'&'+search.content_rule_code+'&'+showMsg.jump_url_type +'&'+scope.row.id">
            <el-button type="primary" size="small">修改</el-button>
          </router-link>
          <el-button type="danger" size="small" @click="deleteContent(scope.row.id)">删除</el-button>
            <i class="el-icon-top sort" @click="contentSort(scope.row,2)"></i>
            <i class="el-icon-bottom sort" @click="contentSort(scope.row,1)"></i>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @current-change="handleCurrentChange"
      v-model:currentPage="search.page"
      :page-size="search.limit"
      layout="total, prev, pager, next"
      :total="total">
    </el-pagination>

    <!-- 新增修改 -->
    <el-dialog :title="title+'内容页'" v-model="dialogFormVisible" width="600px">
      <el-form :model="form" :rules="rules" ref="form">
        <el-form-item label="内容" v-if="showMsg.content_type!==1" label-width="150px">
          <el-input v-model="form.content" v-if="showMsg.content_type ==2" autocomplete="off"></el-input> <!--单行文本 -->
          <el-input v-model="form.content" v-if="showMsg.content_type ==3" type="textarea" autocomplete="off"></el-input><!--多行文本 -->
        </el-form-item>
        <el-form-item label="图文定义" v-if="showMsg.image_url_type!==1" label-width="150px">
          <!-- 图片或视频 -->
          <el-upload
          v-if="showMsg.image_url_type == 2||showMsg.image_url_type==3||showMsg.image_url_type==5"
            class="avatar-uploader"
            :action="s3url"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
          >
            <img v-if="imageUrl" :src="imageUrl" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
          <!-- 富文本 -->
          <editor id="test" ref="editorOne" style="z-index:990;width:700px;" v-model="detail"></editor>
        </el-form-item>
        <el-form-item label="单击图片的跳转地址" label-width="150px">
          <el-input v-model="form.jump_url" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="语言简码" prop="language_code" label-width="150px">
          <el-input v-model="form.language_code" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="标题信息" label-width="150px">
          <el-input v-model="form.title" type="text" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" v-if="!edit" @click="Validate(addconfirm)">新增</el-button>
          <el-button type="primary" v-if="edit" @click="Validate(editconfirm)">修改</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script type="text/ecmascript-6">
import Editor from "../../../components/richtext/index.vue";
export default {
  name: '',
  mixins: [],
  components: {
    Editor
  },
  props: {},
  data () {
    return {
      content_code: '',
      ruleType: '',
      search: {
        page: 1,
        limit: 10,
        kname: '',
        content_rule_code: '',
        language_code: '',
      },
      list: [],
      total: 0,
      dialogFormVisible: false,
      imageUrl: '',
      form: {
        id: '',
        content: '',
        content_rule_code: '',
        img_url: '',
        jump_url: '',
        language_code: '',
        title: '',
      },
      rules: {
      //  content_rule_code: [{ required: true, message: '请输入内容规则编号', trigger: 'blur' }],
       language_code: [{ required: true, message: '请输入语言简码', trigger: 'blur' }],
      },
      title: '',
      edit: false,
      showMsg: {},
      s3url: '',
      languagelist: []
    }
  },
  computed: {},
  watch: {},
  methods: {
    ChangeStatus (item) {
      this.api.RulePageStatus({
        id: item.id,
        status: item.status
      }).then(res => {
        if (res.data.code == 200) {
          this.$message.success('状态修改成功')
          return
        }
        this.$message.error('状态修改失败' + res.data.msg)
      })
    },
    Getlancode () {
      this.api.LanguageList({
        page: 1,
        limit: 100,
      }).then(res => {
        if (res.data.code == 200) {
          this.languagelist = res.data.data.data
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    handleCurrentChange(val) {
      this.search.page = val
      this.Search()
    },
    Search(){
      this.api.RulePageList(this.search).then(res => {
        if (res.data.code == 200) {
          this.list = res.data.data.data
          this.total = res.data.data.page_info.total
          this.showMsg = res.data.data.content_info
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    addContent () {
     this.$nextTick(() => {
        this.$refs.form.resetFields()
      })
      this.form = {}
      this.edit = false
      this.title = '新增'
      this.dialogFormVisible = true
    },
    editContent(item) {
     this.form = {}
      this.$nextTick(() => {
        this.$refs.form.resetFields()
      })
      this.edit = true
      this.title = '修改'
      this.dialogFormVisible = true
      this.api.RulePageDetail({id: item}).then(res => {
        if (res.data.code == 200) {
          this.form=res.data.data.data
          return
        }
        this.$message.error('获取详情失败' + res.data.msg)
      })
    },
    deleteContent(item) {
      this.$confirm('是否删除该语言的内容页?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 删除请求
        this.api.RulePageDelete({id:item}).then(res => {
          if (res.data.code == 200) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
            this.Search()
            return
          }
          this.$message.error('删除失败' + res.data.msg)
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消删除'
        });
      })
    },
    contentSort (item,direction){
      this.api.RulePageSort({
        action: direction,
        id: item.id
      }).then(res => {
        if (res.data.code == 200) {
          this.$message.success('修改排序成功')
          return
        }
        this.$message.error('修改排序失败' + res.data.msg)
      })
    },
    Validate: function (call) {
      this.$refs['form'].validate(res => {
        if (res && call) {
          call()
        }
      })
    },
    update () {
      this.form.content_rule_code = this.$route.params.code.split('&')[2]
      this.api.RulePageUpdate(this.form).then(res => {
        if (res.data.code == 200) {
          this.$message.success(this.title +'成功')
          this.dialogFormVisible = false
          this.Search()
          return
        }
        this.$message.error(this.title +'失败' + res.data.msg)
      })
    },
    addconfirm () {
      this.update()
    },
    editconfirm () {
      this.update()
    },
    goback (){
      this.$router.push('/iot/contentmanage/rule/'+this.content_code+'&'+this.ruleType)
    }
  },
  filters: {},
  mounted () {
    this.content_code = this.$route.params.code.split('&')[0]
    this.ruleType = this.$route.params.code.split('&')[1]
    this.search.content_rule_code = this.$route.params.code.split('&')[2]
    this.Search()
  },
  created () {
    this.Getlancode()
  }
}
</script>

<style>
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
</style>
<style lang="stylus" scoped>
.sort {
  font-size: 16px;
  margin: 0 5px;
  cursor: pointer;
  font-weight: 700px!important;
}
</style>
